import { Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pd-page-content',
  standalone: true,
  imports: [CommonModule],
  styles: [':host { display: flex; flex: 1; flex-direction: column; overflow-y: auto } '],
  template: '<ng-content></ng-content>',
})
export class PageContentComponent {
  @HostBinding()
  public class = 'p-4';
}
