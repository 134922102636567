import { NgModule } from '@angular/core';
import { PageContentComponent } from './page-content/page-content.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { PageComponent } from './page.component';
import { HeaderComponent } from '../header/header.component';

@NgModule({
  declarations: [],
  imports: [PageComponent, PageContentComponent, PageTitleComponent, HeaderComponent],
  exports: [PageComponent, PageContentComponent, PageTitleComponent, HeaderComponent],
})
export class PageComponentsModule {}
