import { Component } from '@angular/core';

@Component({
  selector: 'pd-page-title',
  standalone: true,
  template: ` <div class="border-b-dynamic flex min-h-[65px] flex-col border-b px-4 sm:flex-row">
    <div class="order-2 flex flex-1 font-semibold text-black dark:text-gray-300 sm:order-1">
      <ng-content></ng-content>
    </div>
    <div class="order-1 flex flex-1 sm:order-2">
      <ng-content select="[endActions]"></ng-content>
    </div>
  </div>`,
})
export class PageTitleComponent {}
