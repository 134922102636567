import { Component, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pd-page',
  standalone: true,
  imports: [CommonModule],
  styles: [':host { display: flex; flex: 1; flex-direction: column }'],
  template: '<ng-content></ng-content>',
})
export class PageComponent {
  @HostBinding()
  public class = '';
}
